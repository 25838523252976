$blue: #2dccd3;
$disabled-tile-opacity: 0.5;
$muted-tile-color: #767676;

@import 'spinner';
@import '~animate.css/animate.css';
@import 'oops-dialog';
@import 'json-diff';
@import 'left-nav';
@import 'animate';
@import 'tooltips';
@import 'icons';
@import 'admin/admin';
@import 'variable-usages.scss';
@import 'smacs-bootstrap';
@import 'smacs-bootstrap-variables';
@import 'boostrap-switch-size.scss';
@import 'tables.scss';

@import 'base-form';
@import 'smacs-forms';
@import 'smacs-variables';

@import 'components/labels';
@import 'components/modals';
@import 'components/phone-buttons';
@import 'components/self-serve-user-details';
@import 'components/top-nav';
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/eclipse';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Lato Light', sans-serif;
  background: none;
  font-size: $font-size-base;
  line-height: 21px;
}

span[type='button'],
a.btn {
  -webkit-appearance: none;
}

.alert-inline {
  border-radius: 1.5rem;
  display: inline-block;
  font-family: 'Lato Bold', sans-serif;
  font-size: 0.8rem;
  margin-bottom: 0;
  padding: 4px 10px;
}

.ng-select .ng-select-container {
  border-radius: $border-radius;
  min-height: $default-input-lg-height !important;
}

.ng-select {
  // Allow for empty ng-select options to have the same height as a standard option
  [ng-reflect-ng-item-label=''],
  .ng-option-label:empty {
    display: inline-block;
  }
}

ng-select.ng-select.ng-select-disabled {
  .ng-select-container {
    background-color: #e9ecef;

    .ng-input {
      input {
        color: #495057;
      }
    }
  }
}

ngb-popover-window[id^='ngb-popover'] {
  font-family: $default-smacs-font;

  // The popover and ngbtooltip both share the "arrow" class name and the tooltip arrow will be invisible
  // unless explicitly stated.
  .tooltip {
    .arrow {
      &::before {
        position: absolute !important;
        content: '' !important;
        border-color: transparent !important;
        border-style: solid !important;
      }
    }
  }

  .bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: #000 !important;
      }
    }
  }

  .bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: #000 !important;
      }
    }
  }

  .bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: #000 !important;
      }
    }
  }

  .bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: #000 !important;
      }
    }
  }
}

.font-hairline {
  font-family: 'Lato Hairline';
}

.font-light {
  font-family: 'Lato Light';
}

.font-regular {
  font-family: 'Lato Regular';
}

.font-black {
  font-family: 'Lato Black';
}

.font-bold {
  font-family: 'Lato Bold';
}

.text-large {
  font-size: 110%;
}

.text-80 {
  font-size: 80%;
}

strong {
  font-family: 'Lato Bold';
  font-weight: normal;
}

.strong {
  font-family: 'Lato Bold';
  font-weight: normal;
}

th {
  font-weight: normal;
  font-family: 'Lato Bold';
}

.muted {
  color: #999999;
  font-family: 'Lato Light';

  .svg-icon-class {
    stroke: #999999;
    &--fill {
      fill: #999999;
    }
  }
}

label {
  font-family: 'Lato Regular';
  font-weight: normal;
}

legend {
  font-family: 'Lato Light';
  font-weight: normal;
  margin: 30px 0 10px 0;
  line-height: 36px;
}

.col-form-label + * {
  smacs-toggle-switch {
    > * {
      margin-top: 0.4rem;
    }
  }
}

th {
  font-family: 'Lato Bold';
  font-weight: normal;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.transform-scale-8 {
  transform: scale(0.8);
}

.top-buffer {
  margin-top: 20px;
}

.bottom-buffer {
  margin-bottom: 20px;
}

#forbiddenErrorDialog {
  text-align: center;
  font-size: 16px;
}

.s8-button-container {
  clear: both;
  position: fixed;
  right: 15px;
  bottom: 25px;
}

.s8-button-container .btn {
  border-radius: 0;
  height: 40px;
  margin-right: 5px;
}

.s8-tree-container {
  padding-left: 5px;
  border-right: thin #ccc solid;
  overflow: auto;
  max-height: 500px;
}

.s8-tree-item {
  margin-left: 15px;
  line-height: 26px;
  cursor: pointer;
}

.s8-tree-root {
  line-height: 26px;
  cursor: pointer;
}

.s8-tree-item .s8_selected_node > a {
  font-weight: bold;
}

.s8-title h2 {
  margin-top: 20px;
  margin-bottom: 0;
}

.s8-title h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.has-error .s8-required {
  color: $brand-danger;
}

.s8-multiple-control-validator {
  margin-top: -13px;
}

.s8-null-offset {
  padding-left: 0;
}

.macDetails table,
th,
td {
  text-align: left;
  padding: 5px;

  p-dropdown {
    .p-dropdown {
      width: 100%;

      .p-dropdown-panel {
        max-width: 100%;
      }
    }
  }
}

.macDetails table {
  margin: 10px 5px;
}

.macDetails td {
  border: none;
}

#mac-overwrite-warn {
  margin-top: 5px;
  padding: 5px;
}

.s8-control-value {
  padding-top: 7px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

/* https://docs.angularjs.org/api/ng/directive/ngCloak */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.smacs-error-notification {
  position: fixed;
  right: 28px;
  bottom: 50px;
  z-index: 900;
  min-width: 300px;
}

i.field-error:before {
  font-family: FontAwesome;
  font-style: normal;
  content: '\f12a';
  color: $brand-danger;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.modal-50-percent {
  width: 50%;
  min-width: 800px;
}

.modal-45-percent {
  width: 630px;
  min-width: 630px;
}

.modal-60-percent {
  width: 60%;
  min-width: 800px;
}

.modal-80-percent {
  width: 80%;
  min-width: 800px;
}

.modal-90-percent {
  width: 90%;
  min-width: 800px;
}

.modal-xxl {
  width: 100%;
  max-width: 1500px;
}

.scrollableTab {
  max-height: 600px;
  max-width: 600px;
  overflow: auto;
}

#distribution-list-info {
  padding-top: 20px;
}

#distribution-list-members td,
#distribution-list-members th {
  text-align: center;
  vertical-align: middle;
}

.button-badge {
  margin-right: 0.5em;
}

.ranges-section-scrollable {
  max-height: 300px;
  overflow-y: auto;
}

.range-group {
  margin-bottom: 3px;
  zoom: 90%;
}

code.audit {
  padding: 0;
}

//Patch for chrome square drop downs
select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  //padding: @include 5em;
  padding-right: 1.5em;
}

.login-and-ss {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 75px;
  height: auto;

  body {
    padding: 0;
  }
}

#s8-login-form {
  margin-top: 75px;
  margin-bottom: 20px;
  min-height: 320px;
  border: #cccccc solid thin;
  padding: 15px;
  background-color: #ffffff;
}

#s8-sso-login-form {
  min-height: 178px;
  border: #cccccc solid thin;
  padding: 15px;
  background-color: #ffffff;
}

.login-container {
  padding-top: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .login-container-inner {
    max-width: 525px;
    margin: 0 auto;
  }
}

.company-logo-image {
  display: inline-block;
  max-height: 68px;
  max-width: 200px;
}

.self-serve-logo-svg {
  max-height: 40px;
}

// Call to action button (used on login and nav search)
.btn-call-to-action:hover,
.btn-call-to-action:focus,
.btn-call-to-action:active,
.btn-call-to-action {
  background: $btn-call-to-action-bg;
  color: $btn-call-to-action-color;
}

a.btn i.fa {
  line-height: inherit;
}

.searchbar {
  width: 150px;
}

@media (min-width: 1100px) {
  .searchbar {
    width: 300px;
  }
}

// workaround for ui-select bug https://github.com/angular-ui/ui-select/issues/379
.ui-select-multiple.ui-select-bootstrap {
  min-height: $input-height-base;
}

.clickable-cursor {
  cursor: pointer;
}

.clickable-cursor:hover {
  color: $brand-primary;
}

.clickable-cursor.active:hover {
  color: $component-active-color;
}

table.dn-menu-table tr td {
  vertical-align: middle;
}

.header-fixed-3 {
  width: 100%;
}

.header-fixed-3 > thead,
.header-fixed-3 > tbody,
.header-fixed-3 > thead > tr,
.header-fixed-3 > tbody > tr,
.header-fixed-3 > thead > tr > th,
.header-fixed-3 > tbody > tr > td {
  display: block;
}

.header-fixed-3 > tbody > tr:after,
.header-fixed-3 > thead > tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed-3 > tbody {
  overflow-y: auto;
  height: 400px;
}

.header-fixed-3 > tbody > tr > td,
.header-fixed-3 > thead > tr > th {
  width: 33%;
  float: left;
}

.swagger-ui .operation .content textarea,
.swagger-ui pre {
  resize: both;
}

.tile {
  font-size: 13px;
  min-height: 10.6rem;
  cursor: pointer;
  margin-top: 3px;
  padding: 30px 5px 0 5px;
  overflow: hidden;
  margin-bottom: 20px;
  line-height: 1.2;
  border-radius: $border-radius;
}

.tile-default {
  border: thin solid $btn-info-bg;

  &.muted {
    opacity: $disabled-tile-opacity;

    &:hover {
      border-color: $muted-tile-color;
      outline-color: $muted-tile-color;
      color: $muted-tile-color;
      border-radius: $border-radius;

      .svg-icon-class {
        stroke: $muted-tile-color;
        &--fill {
          fill: $muted-tile-color;
        }
      }
    }
  }
}

.tile-primary {
  border: thin solid $btn-primary-border;
}

.tile-info {
  border: thin solid $btn-info-border;
}

.tile-warning {
  border: thin solid $btn-warning-border;
}

.tile-danger {
  border: thin solid $state-danger-border;
}

.tile-description {
  height: 60px;
  display: block;
}

.tile-default:hover {
  outline: 3px solid #fff;
  color: #fff;
  border-color: #fff;
}

.tile-warning:hover {
  outline: none;
  color: #fff;
  border-color: $btn-warning-border;
}

.tile-danger:hover {
  outline: none;
}

.tile.muted {
  cursor: not-allowed;
}

.tile .muted {
  cursor: not-allowed;
}

.tile-header {
  margin-top: -30px;
  display: block;
  text-align: right;
  height: 30px;
  padding: 8px 8px 0 5px;
  font-size: 20px;
}

.page-header-icon {
  font-size: 60px;
}

.btn:disabled {
  cursor: not-allowed !important;
}

.btn-input-copy {
  text-align: center;
  background-color: #fff;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-left: 0 !important;
  border-radius: 0;
  &:disabled {
    border-left: 1px solid #212529;
  }
}

.btn-outline-primary {
  color: $brand-primary;
  background-color: inherit;
  border-color: $brand-primary;

  &:disabled,
  &:disabled:first-child:active {
    color: $brand-primary;
    background-color: inherit;
    border-color: $brand-primary;
  }

  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: #fff;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
  }
}

.btn-outline-danger {
  color: $brand-danger;
  background-color: inherit;
  border-color: $brand-danger;

  &:disabled,
  &:disabled:first-child:active {
    color: $brand-danger;
    background-color: inherit;
    border-color: $brand-danger;
  }

  &:hover:not(:disabled) {
    color: #fff !important;
    background-color: $btn-danger-bg;
    border-color: $btn-danger-border;
  }
}

.btn-outline-warning {
  color: $brand-warning;
  background-color: inherit;
  border-color: $brand-warning;

  &:disabled,
  &:disabled:first-child:active {
    color: $brand-warning;
    background-color: inherit;
    border-color: $brand-warning;
  }

  &:hover:not(:disabled) {
    color: #fff;
    background-color: $btn-warning-bg;
    border-color: $btn-warning-border;
  }
}

.btn-outline-info {
  color: $brand-info;
  background-color: inherit;
  border-color: $brand-info;

  &:disabled,
  &:disabled:first-child:active {
    color: $brand-info;
    background-color: inherit;
    border-color: $brand-info;
  }

  &:hover:not(:disabled) {
    color: #fff;
    background-color: $btn-info-bg;
    border-color: $btn-info-border;
  }
}

.btn-outline-success {
  color: $brand-success;
  background-color: inherit;
  border-color: $brand-success;

  &:disabled,
  &:disabled:first-child:active {
    color: $brand-success;
    background-color: inherit;
    border-color: $brand-success;
  }

  &:hover:not(:disabled) {
    color: #fff;
    background-color: $btn-success-bg;
    border-color: $btn-success-border;
  }
}

.device-list-delete-button {
  margin-top: 7px;
  border-radius: 100%;
}

.right-side-drag-box {
  border-left: dashed #aaa thin;
}

.smacs-connected-list {
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
}

.smacs-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 0;
}

.smacs-page-alert {
  padding: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smacs-drag-placeholder {
  background-color: #eee;
  height: 30px;
  border: dashed #aaa thin;
  margin-top: 4px;
  margin-bottom: 4px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.clear {
  clear: both;
}

.formly-glyphicon {
  right: 1em; // offset fix
}

.formly-inline-checkbox {
  display: inline-block;
  padding-right: 20px;
}

.smacs-inline-form-group {
  .has-error .form-control-feedback {
    right: 4em;
  }

  .smacs-inline-form-input {
    display: flex;

    > *:last-child {
      border-left-width: 0;
    }
  }
}

.not-allowed-cursor {
  cursor: not-allowed;
  color: $text-muted;
}

.user-avatar {
  max-height: 200px;
}

.dn-button {
  padding: 0;
  font-size: small;
}

.did-button {
  padding: 0;
  font-size: small;
}

.ui-sortable-selected {
  background-color: #e6e6e6 !important;
  border-color: #adadad;
}

.input-group-addon-avoid-feedback-overlap {
  padding-right: 22px;
}

.hide-common-fields {
  margin-left: -15px;
  margin-top: -5px;
  margin-bottom: 5px;
}

.regenerate-default-link,
.regenerate-default-link:not([href]):not([tabindex]) {
  color: $brand-primary;

  &:hover {
    color: darken($brand-primary, 10%);
  }
}

.readonly-clickable[readonly] {
  background-color: $white;
}

.animation-delay-half-second {
  animation-delay: 500ms;
}

.default-value-preview {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.smacs-left-nav .list-group-item.disabled .icon,
.smacs-left-nav .list-group-item.disabled .operations-menu-text,
.smacs-left-nav .list-group-item.disabled .delete-button-text {
  opacity: 0.5;
}

.tile-default {
  background: $btn-default-bg;
  border: thin solid $btn-info-bg;
  color: $btn-info-bg;

  &:hover {
    outline: 3px solid $brand-primary;
    color: $brand-primary;
    border-color: $brand-primary;
    border-radius: 3px;

    .svg-icon-class {
      stroke: $brand-primary;
      &--fill {
        fill: $brand-primary;
      }
    }
  }

  .svg-icon-class {
    stroke: $btn-info-bg;
    &--fill {
      fill: $btn-info-bg;
    }
  }
}

.tile-primary {
  background: $btn-primary-bg;
  border-color: $btn-primary-border;
  color: $btn-primary-color;
}

.tile-info {
  background: $btn-info-bg;
  border-color: thin solid $btn-info-border;
  color: $btn-info-color;

  &.muted {
    .svg-icon-class {
      stroke: $muted-tile-color;
      &--fill {
        fill: $muted-tile-color;
      }
    }
  }

  &:hover {
    outline: 3px solid $brand-primary;
    color: $white;
    border-color: $brand-primary;
    border-radius: 3px;
  }

  .svg-icon-class {
    stroke: $btn-info-color;
    &--fill {
      fill: $btn-info-color;
    }
  }
}

.tile-in-progress {
  background: $btn-default-bg;
  border: thin solid $btn-warning-border;
  color: $btn-warning-bg;

  &:hover {
    outline: 3px solid $btn-warning-border;
    color: $btn-warning-bg;
    border: thin solid $btn-warning-border;
  }

  .svg-icon-class {
    stroke: $btn-warning-bg;
    &--fill {
      fill: $btn-warning-bg;
    }
  }
}

.tile-warning {
  background: $btn-warning-bg;
  border-color: thin solid $btn-warning-border;
  color: #fff;

  &:hover {
    outline: none;
    color: #fff;
    border-color: $btn-warning-border;
  }

  .svg-icon-class {
    stroke: #fff;
    &--fill {
      fill: #fff;
    }
  }
}

.tile-danger {
  background: lighten($btn-danger-bg, 33%);
  border-color: thin solid $btn-danger-border;
  color: $btn-danger-bg;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none !important;
}

.faded-opacity {
  opacity: 0.5;
}

.modal-circular-icon {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: $btn-info-bg;
}

.overflow-visible {
  overflow: visible;
}

a.pointer-events-none {
  pointer-events: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.switch {
  .custom-help-text {
    font-size: 13px;
  }
}

.bulk-line-appearance-management {
  ngb-typeahead-window {
    transform: translate(0, -45px) !important;

    .dropdown-item {
      height: 3.125rem !important;
      padding: 0 !important;
      width: auto !important;

      .dropdown-item__content {
        padding: 0.25rem 1.5rem;

        &--disabled {
          background-color: white;
          color: #212529;

          cursor: not-allowed;

          &:hover {
            border: 1px solid white;
          }

          &:not(:hover) {
            border: 1px solid transparent;
          }

          small {
            color: #999999 !important;
          }
        }
      }

      &.active {
        width: auto;

        small {
          color: $white;
        }
      }

      span {
        &.disabled:hover {
          cursor: not-allowed;
          background-color: transparent;
        }
      }
    }
  }

  ngb-typeahead-window.dropdown-menu {
    width: 100%;
    max-height: 200px;
  }
}

.search-typeahead {
  ngb-typeahead-window.dropdown-menu {
    margin-left: -31px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
  }
}

.zero-touch-provisioning-site-filter {
  .p-dropdown.p-component.p-dropdown-open.p-dropdown-clearable {
    width: 100%;
  }
}

.user-detail-tile-title {
  span {
    font-size: 14px;
  }
}

.min-width-25 {
  min-width: 25%;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.svg-icon-class {
  height: 2.5rem;
}

.add-service-tile,
.tile-info {
  .svg-icon-class {
    svg {
      height: 2.5rem;
    }
  }
}

.reassign-user {
  .svg-icon-class {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.customize-width {
  width: 1rem;
}

smacs-view-members {
  position: relative;
  .popover {
    margin-top: -8px !important;
    max-width: none;
    min-width: 100%;
  }
}

.tooltip {
  z-index: 8000;
}

.disable-all-animations {
  *,
  *::before,
  *::after {
    animation: none !important;
    transition: none !important;
  }
}

.smacs-datatable-content {
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.font-size-32 {
  font-size: 32px;
}

.user-profile-picture {
  border: 1px solid $brand-info;
  border-radius: 100%;
  object-fit: cover;
}

.round-image-table {
  border-radius: 50%;
  max-width: 32px;
  max-height: 32px;
  margin-right: 5px;
}

.round-image-table-icon {
  font-size: 32px;
}

.round-image-navbar {
  border-radius: 50%;
  max-width: 20px;
  max-height: 20px;
  margin-right: 5px;
}

.round-image-navbar-icon {
  font-size: 19px;
}

.text-separator {
  margin: 0 5px;
}

table {
  tr:first-of-type {
    td:first-of-type,
    th:first-of-type {
      border-top-left-radius: $border-radius;
    }

    td:last-of-type,
    th:last-of-type {
      border-top-right-radius: $border-radius;
    }
  }

  tbody > tr:last-of-type {
    td:first-of-type {
      border-bottom-left-radius: $border-radius;
    }

    td:last-of-type {
      border-bottom-left-radius: $border-radius;
    }
  }
}

app-breadcrumb-card-item {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato Bold', sans-serif;
  }
  h1,
  h2 {
    text-decoration: underline;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 21px;
  }
  h3 {
    font-size: 17.5px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12.25px;
  }
  h6 {
    font-size: 11.9px;
  }
  blockquote {
    border-left: 5px solid #ccc;
    padding: 5px 5px 5px 15px;
    margin: 20px 0;
    color: #666;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;

    * {
      margin: 0;
    }
  }
}

app-entity-table {
  .has-error {
    .ng-select .ng-select-container {
      border-color: $brand-danger;
    }
  }
}

ng-dropdown-panel.smacs-filter-select-with-icons {
  .ng-dropdown-panel-items .ng-option {
    padding: 0;
  }
  .filter-select-option-tooltip-container {
    display: inline-block;
    padding: 8px 10px;
    width: 100%;
  }
}

strong > i,
strong > em {
  margin-right: 2px;
}
