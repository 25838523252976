/*****************************
   ANIMATE.CSS ENHANCEMENTS
*****************************/

.animated.slow {
  animation-duration: 1.5s;
}

.animated.slower {
  animation-duration: 2s;
}

.animated.fast {
  animation-duration: 0.5s;
}

.animated.faster {
  animation-duration: 0.25s;
}

.vibrate {
  animation-name: vibrate;
  animation-duration: 0.2s;
}

@keyframes vibrate {
  0% {
    -webkit-transform: rotate(0.8deg);
  }
  50% {
    -webkit-transform: rotate(-0.8deg);
  }
}

@-webkit-keyframes vibrate {
  0% {
    -webkit-transform: rotate(0.8deg);
  }
  50% {
    -webkit-transform: rotate(-0.8deg);
  }
}

//Flip in with primary brand highlight
@keyframes flip-in-x-primary {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  to {
    transform: perspective(400px);
  }
}

.flip-in-x-primary {
  backface-visibility: visible !important;
  animation-name: flip-in-x-primary;
}

@keyframes flip-in-y-primary {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    background: $btn-primary-bg;
    color: $btn-primary-color;
  }

  to {
    transform: perspective(400px);
  }
}

.flip-in-y-primary {
  backface-visibility: visible !important;
  animation-name: flip-in-y-primary;
}
