$phone-button-height: 3.5rem;

// Colors
$color-black-1: #57606f;
$color-black-2: #2f3542;
$color-gray-1: #a4b0be;
$color-gray-2: #747d8c;
$color-green-1: #2ecc71;
$color-green-2: #27ae60;
$color-gray-3: #dfdfdf;
$color-gray-4: #d9dadd;
$color-white-1: #ffffff;
$color-white-2: #f1f2f6;
