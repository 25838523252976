@import 'smacs-variables';

/*
  Add all styles relying on on dynamic LESS variables here
  This should mirror variable-usages.less on the server side
*/

.self-serve-user-details-cluster {
  .btn-link {
    color: $dropdown-link-color;

    &:hover {
      background-color: $dropdown-link-hover-bg;
      color: $brand-primary;
    }
  }
}
