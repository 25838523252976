// Animations to match legacy ui-bootstrap modals
.modal.show .modal-dialog {
  transform: none;
  animation: 0.2s ease 0s normal forwards 1 smacsSlideDown;
  animation-timing-function: linear;

  @keyframes smacsSlideDown {
    0% {
      opacity: 0.5;
      transform: translate(0, -30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.modal-backdrop {
  animation: 0.2s ease 0s normal forwards 1 smacsFadeIn;
  animation-timing-function: linear;

  @keyframes smacsFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
}

.modal-dialog {
  margin: 1.75rem auto; // To remove the scrollbar that appears when the modal is at max height
}

.component-host-scrollable {
  overflow: auto !important; // Overrides hidden overflow, which cuts off the footer
}

.modal {
  z-index: 1000000 !important; // To ensure oops dialogs and the like are always on top of everything.
}
.modal-backdrop {
  z-index: 999999 !important; // To ensure the backdrop appears on top of everything other than the modal.
}

.modal-dialog.modal-lg {
  margin: 3.5rem auto;
}

ziro-release-notes-modal {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato Bold';
  }
  h1 {
    font-size: 23px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 11px;
  }
  video {
    margin-bottom: 1rem;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .modal-body--dynamic__scrollable {
    max-height: 80vh;
  }
  .meta {
    display: none;
  }
  .emoji {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }
}
