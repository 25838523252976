#error-dialog-content {
  text-align: left;
  resize: both;
  height: 300px;
  overflow: scroll;
  word-wrap: normal;
  zoom: 75%;
  padding: 10px;
  margin-bottom: 10px;
  border: thick solid rgb(238, 238, 238);
  display: block;
}
