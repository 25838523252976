.smacs-base-form-label {
  text-align: right;
}

.smacs-base-form-asterisk {
  margin-right: 0.25rem;
}

.smacs-base-form-info {
  margin-left: 0.25rem;
}

.form-control:focus {
  box-shadow: none;
}

.smacs-base-form-validation-message {
  @include font-size(80%);
}

.base-form-feedback {
  &.base-form-info,
  .base-form-info {
    color: $brand-info;

    .smacs-horizontal-form-validation-message {
      color: $brand-info;
    }

    .form-control,
    .ng-select-container {
      border-color: $brand-info;
    }
  }

  &.base-form-success,
  .base-form-success {
    color: $brand-success;

    .smacs-horizontal-form-validation-message {
      color: $brand-success;
    }

    .form-control,
    .ng-select-container {
      border-color: $brand-success;
    }
  }

  &.base-form-warning,
  .base-form-warning {
    color: $brand-warning;

    .smacs-horizontal-form-validation-message {
      color: $brand-warning;
    }

    .form-control,
    .ng-select-container {
      border-color: $brand-warning;
    }
  }

  &.base-form-error,
  .base-form-error {
    color: $brand-danger;

    .smacs-horizontal-form-validation-message {
      color: $brand-danger;
    }

    .form-control,
    .ng-select-container {
      border-color: $brand-danger;
    }
  }
}
