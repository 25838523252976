ng-autocomplete {
  .autocomplete-container {
    box-shadow: none;
    height: auto;
    .input-container {
      input {
        @extend .form-control;
      }
      .x {
        display: none;
      }
    }

    .suggestions-container {
      box-shadow: none;
      ul li {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        &:first-of-type {
          border-top: 1px solid #ced4da;
        }
        &:last-of-type {
          border-bottom: 1px solid #ced4da;
        }
        a {
          padding: 5px 10px;
          font-size: 0.8rem;
          b {
            font-family: 'Lato Bold';
            color: $brand-primary;
          }
        }
      }
    }
  }
}
