@import './variables';
@import './mixins';

smacs-phone-buttons {
  .phone-buttons-top-nav {
    margin-bottom: 1rem;
  }

  .gu-transit {
    display: none !important;
  }

  li.gu-mirror {
    list-style: none;
  }

  &.gu-unselectable {
    cursor: grabbing;
  }

  .phone-buttons-drag-target-candidate {
    background: darken($gray-lighter, 5%);
  }

  .nav-item {
    &.phone-buttons-unmovable {
      cursor: not-allowed;
    }

    &.phone-buttons-disabled {
      opacity: 0.2;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
      filter: alpha(opacity=20);
      background-color: $gray-lighter;
    }

    &.flipInX {
      background-color: darken($gray-lighter, 5%) !important;
    }

    &.phone-buttons-drop-target {
      transition: background-color !important;
      transition-duration: 0.4s !important;
      background: darken($gray-lighter, 15%);

      .phone-buttons-delete-toggle-button {
        visibility: hidden;
      }
    }

    &.phone-buttons-unmovable {
      .phone-buttons-delete-toggle-button {
        display: none;
      }
    }
  }

  app-phone-buttons-standard {
    display: flex;

    .tab-content {
      width: 100%;
    }
  }

  app-phone-buttons-expansion {
    ul {
      column-count: 4;
      list-style: none;
      padding: 0;
    }
  }
}

app-phone-buttons-dnd-button {
  max-width: 100%;
}

.draggable-card {
  background-color: $white;

  &.phone-buttons-standard-selected-tab {
    .nav-item {
      background-color: $brand-primary !important;

      &.phone-buttons-drop-target {
        background-color: darken($brand-primary, 10%) !important;
      }

      .nav-pill-type,
      .nav-pill-value {
        color: $white !important;
      }
    }
  }

  &:hover {
    background-color: darken($gray-lighter, 5%);
    border-radius: 0.5rem;
    cursor: pointer;
  }

  &:active {
    cursor: grabbing;
  }
}

.deletable-card {
  border-radius: 0.5rem;
  font-size: 80%;
  &--danger {
    background-color: lighten($brand-danger, 40%);
  }
  &--info {
    background-color: lighten($brand-info, 70%);
  }
}

.delete-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phone-buttons-delete-confirmation-buttons {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}

.deletable-card-section {
  width: 50%;
}

.phone-buttons-tab {
  &.nav-link {
    padding: 0;
  }

  .nav-pill {
    align-items: center;
    display: flex;
    padding: 0 0.5rem;
    height: $phone-button-height;
  }

  .phone-buttons-delete-toggle-button {
    display: none;
  }

  &:hover,
  &.gu-mirror {
    .phone-buttons-delete-toggle-button {
      display: inline-block;
      &:hover {
        color: $white;
      }

      &--danger {
        color: $brand-danger;
        border-color: $btn-danger-border;
        &:hover {
          background-color: $brand-danger;
        }
      }

      &--info {
        color: $brand-info;
        border-color: $btn-info-border;
        &:hover {
          background-color: $brand-info;
        }
      }

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          color: $brand-info;
          background-color: inherit;
        }
      }
    }
  }

  &.gu-mirror {
    background-color: $gray-lighter;
    border-radius: 0.5rem;

    .phone-buttons-delete-toggle-button {
      background-color: $white !important;
      &--danger {
        color: $brand-danger !important;
      }
      &--info {
        color: $brand-info !important;
      }
    }
  }

  .nav-pill-index-sd {
    padding: 2px 8px;
    border-radius: 3px;
    background-color: $brand-info;
    color: $white;
    margin-right: 15px;
  }

  .nav-pill-index-blf {
    padding: 2px 5px;
    border-radius: 3px;
    background-color: $brand-info;
    color: $white;
    margin-right: 15px;
  }

  .nav-pill-info {
    text-align: left;
    flex-shrink: 1;
    min-width: 0;
    white-space: nowrap;
    width: 100%;
  }

  .nav-pill-type {
    color: $brand-info;
  }

  .nav-pill-value {
    font-weight: 700;
    color: $brand-primary;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  &--inverse {
    .nav-pill {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      height: 3.5rem;
      flex-direction: row-reverse;

      .phone-buttons-delete-toggle-button {
        display: block;
        visibility: hidden;
      }

      .deletable-card-section {
        justify-content: end;
      }

      &:hover {
        .phone-buttons-delete-toggle-button {
          visibility: visible;
        }
      }
    }

    .nav-pill {
      .nav-pill-index-sd {
        margin-right: 0;
        margin-left: 15px;
      }
      .nav-pill-index-blf {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    .nav-pill-info {
      text-align: right;
      flex-shrink: 1;
      min-width: 0;
      width: 100%;
    }
  }
}
