/**
 * This file contains overwrites for bootstrap 4 variables
 */

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$brand-primary: #2dccd3;
$brand-info: #202020;

$brand-success: #42d740;
$brand-warning: #fd7a3d;
$brand-danger: #fb4656;

$brand-light: #f8f9fa;
$brand-dark: #343a40;

$primary: $brand-primary;
$success: $brand-success;
$info: $brand-info;
$warning: $brand-warning;
$danger: $brand-danger;
$light: $brand-light;
$dark: $brand-dark;

$border-radius: 2px;
$border-radius-lg: 0;
$border-radius-sm: 0;

$breadcrumb-bg: $gray-lighter;
$breadcrumb-padding-x: 1rem;
$breadcrumb-padding-y: 0.75rem;
$default-input-lg-height: 34.5625px;
