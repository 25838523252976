.toast-top-right {
  top: 75px;
  right: 12px;
}

#toast-container {
  pointer-events: all;
  .toast {
    background-image: none !important;
    border-radius: 45px 0 0 45px;
    box-shadow: 0 0 12px #000000;
    color: $white;
    opacity: 0.9;
    padding: 15px;
    animation-name: slideInRight;
    animation-duration: 0.3s;

    .toast-close-button {
      color: $white;
      font-size: 20px;
      font-weight: bold;
      opacity: 0.9;
      position: absolute;
      top: 0;
      right: 0.5rem;
    }
  }

  .smacs-toast {
    display: flex;
    position: relative;
    height: 100%;

    .smacs-toast-icon-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .smacs-toast-icon-bg {
        font-size: 4em;
      }

      .smacs-toast-icon {
        position: absolute;
        font-size: 2em;
      }
    }

    .smacs-toast-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.6em;

      .smacs-toast-title {
        margin: 0;
      }

      .smacs-toast-body {
        width: fit-content;
      }
    }
  }
}
