@import './admin-menu';

li.service-subsection {
  list-style-type: none;
}

.smacs-admin-menu {
  width: 250px;
  top: 65px;
  bottom: 0;
  &.release-available {
    top: 101px;
  }
}
