app-self-serve-user-details {
  .btn-primary[type='submit'] {
    color: $white;
  }
}

.self-serve-back {
  .btn {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      text-decoration: none;
    }
    .fa {
      margin-right: 5px;
    }
  }
}
