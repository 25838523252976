.table th,
.table td {
  &.vertical-align-middle {
    vertical-align: middle;
  }
}

app-entity-table {
  .entity-table-thumbnail {
    width: 2rem;
    height: 2rem;
  }
}

.thumbnail-container {
  height: 2rem;
  width: 2rem;
}

@media (max-width: 1366px) {
  app-entity-table {
    .entity-table-actions {
      .btn {
        margin-bottom: 2px;
      }
    }
  }
}
