@font-face {
  font-family: 'Lato Regular';
  src: url('/static/fonts/lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: url('/static/fonts/lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Black';
  src: url('/static/fonts/lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('/static/fonts/lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('/static/fonts/lato/Lato-Hairline.ttf') format('truetype');
}
