///* ---------------------------------------------------------------------------------------------------------------------
// This fixes what was broken by the removal of smacs-bootstrap.scss (contained bootstrap 3)
//--------------------------------------------------------------------------------------------------------------------- */
@import 'smacs-bootstrap-variables-overwrite';

/* Misc. */
.fade.in {
  opacity: 1;
}

/* Alert */
.alert {
  border-radius: $border-radius;
}

.alert-danger {
  background-color: lighten($danger, 33%);
  border-color: lighten($danger, 10%);
  color: $danger;
}

.alert-success {
  background-color: lighten($success, 33%);
  border-color: lighten($success, 10%);
  color: $success;
}

.alert-warning {
  background-color: lighten($warning, 33%);
  border-color: lighten($warning, 10%);
  color: $warning;
}

.alert-info {
  background-color: lighten($info, 85%);
  border-color: lighten($info, 20%);
  color: $info;
}

/* Badges */
.badge {
  font-family: 'Lato Regular', sans-serif;
  letter-spacing: 0.5px;
}

/* Button Group */
.btn-group-wrapped {
  width: 100%;

  .btn-wrapper {
    width: 25%;

    .btn {
      display: block;
      width: 100%;
    }
  }
}

.match-input-lg {
  min-height: $default-input-lg-height !important;
}

/* Buttons */
.btn {
  font-size: 0.9rem;
  font-family: 'Lato Regular', sans-serif;
  border-radius: $border-radius;
}

.input-btn-end,
.input-text-end {
  border-top-left-radius: 0 !important;
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: 0 !important;
}

.input-text-start {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: $border-radius !important;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-success,
.btn-success.disabled,
.btn-success:disabled,
.btn-warning,
.btn-warning.disabled,
.btn-warning:disabled,
.btn-danger,
.btn-danger.disabled,
.btn-danger:disabled,
.btn-info,
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;

  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: #fff;
  }
}

.btn.disabled,
.btn[disabled],
.btn[disabled='disabled'],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}

.btn-xs {
  font-size: 13px;
  padding: 2px 5px;
}

.btn-primary,
.btn-primary:active:disabled {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;

  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: $btn-primary-color;
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-border-hover;
  }
}

.btn-success,
.btn-success:active:disabled {
  color: $btn-success-color;
  background-color: $btn-success-bg;
  border-color: $btn-success-border;

  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: $btn-success-color;
    background-color: $btn-success-bg-hover;
    border-color: $btn-success-border-hover;
  }
}

.btn-warning,
.btn-warning:active:disabled {
  color: $btn-warning-color;
  background-color: $btn-warning-bg;
  border-color: $btn-warning-border;

  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: $btn-warning-color;
    background-color: $btn-warning-bg-hover;
    border-color: $btn-warning-border-hover;
  }
}

.btn-danger,
.btn-danger:active:disabled {
  color: $btn-danger-color;
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;

  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: $btn-danger-color;
    background-color: $btn-danger-bg-hover;
    border-color: $btn-danger-border-hover;
  }
}

.btn-default,
.btn-outline-default {
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border-color: $btn-default-border;

  &:hover:not(:disabled) {
    color: $btn-default-color-hover;
    background-color: $btn-default-bg-hover;
    border-color: $btn-default-border-hover;
  }
  &:disabled {
    border-color: $btn-default-border;
  }
}

.btn-link {
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: 'Lato Light', sans-serif;
}

.btn.btn-readonly {
  pointer-events: none;
}

/* Caret */
.ui-select-container {
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

/* Disabled States */
/* These fix tooltips not working when element is disabled */
button.btn.disabled,
a.btn.disabled {
  pointer-events: auto;
}

/* Dropdown */
.dropdown-header {
  padding: 3px 20px;
}

.dropdown-no-caret {
  .dropdown-toggle::after {
    display: none;
  }
}

.dropdown-menu {
  min-width: 100%;
  max-height: 30vh;
  overflow-y: auto;

  .dropdown-item {
    min-width: 100%;

    a {
      cursor: pointer;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ui-select-bootstrap .ui-select-choices-row {
  &.active {
    > span > div > p {
      color: $white;
    }
  }

  > span {
    padding: 3px 25px;

    > div > p {
      color: #6c757d;
      display: inline;
    }
  }
}

// Maybe this should be in "open"? There are a lot of cases of .open classes being missing
.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

/* Forms */
.form-horizontal {
  .col-form-label {
    text-align: right;
  }

  .has-feedback {
    .form-control-feedback {
      right: 15px;
    }
  }

  .has-editor-button {
    .form-control-feedback {
      right: 90px;
    }
  }
}

.form-control {
  font-size: 0.9rem;
}

.form-check-input-wrapper {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  width: 12px;
  height: 12px;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
  }
}

.has-feedback {
  .form-text {
    color: inherit;
  }
}

.form-control-feedback {
  color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1040;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;

  &.form-control-feedback--left {
    left: 0;
    right: auto;
  }
}

.has-info {
  color: $brand-info;

  .form-control {
    border-color: $brand-info;
  }
}

.has-success {
  color: $brand-success;

  .form-control {
    border-color: $brand-success;
  }
}

.has-warning {
  color: $brand-warning;

  .form-control {
    border-color: $brand-warning;
  }
}

.has-error {
  color: $brand-danger;

  .form-control {
    border-color: $brand-danger;
  }
}

/* Input Group */
.input-group-addon {
  padding: 0.25rem 0.75rem;
  text-align: center;
  background-color: $gray-lighter;
  border: 1px solid $input-border-color;
  border-radius: 0;

  &:first-child {
    border-right: 0;
  }

  &:last-child {
    border-left: 0;
  }
}

/* Legend */
legend {
  border-bottom: 1px solid $legend-border-color;
}

/* List Group */
.list-group-item[uib-tooltip].disabled,
.list-group-item[uib-tooltip]:disabled {
  pointer-events: auto;
}

.list-group.list-group-striped .list-group-item:nth-child(even) {
  @extend .bg-light;
}

/* Modal */
.modal {
  &.fade {
    opacity: 1;
  }

  .close {
    opacity: 0.3;
  }

  &.in .modal-dialog {
    transform: translate(0, 0);
  }
}

.modal-header {
  border-bottom: 0;
}

.modal-content {
  border-radius: $border-radius;
  border: 0;
  box-shadow: 0 25px 40px 5px rgba(0, 0, 0, 0.25);

  .modal-footer {
    border-top: 0;
  }
}

.modal-backdrop {
  &.in {
    opacity: 0.5;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    margin: 30px auto;

    &.modal-80-percent {
      max-width: none;
    }

    &.modal-90-percent {
      max-width: none;
    }
  }
}

/* Nav */
nav .nav-item > .nav-link,
.navbar-dark .navbar-nav .nav-link {
  &.active {
    color: $white;
    background-color: $brand-primary;
  }

  &:hover {
    text-decoration: none;
    color: $brand-primary;
    background-color: #eeeeee;
  }
}

.nav-pills,
.nav-tabs {
  .nav-link {
    &.btn {
      font-family: 'Lato Light', sans-serif;
      font-size: $font-size-base;
    }
  }
}

.nav-pills {
  > li {
    border-radius: $border-radius;

    &.active {
      > a,
      > a:hover,
      > a:focus {
        color: $white;
        background-color: $brand-primary;
      }
    }

    a:hover,
    a:focus {
      background-color: $gray-lighter;
    }
  }
}

.nav-tabs {
  .nav-item .nav-link:hover {
    background-color: $gray-lighter;
  }

  .nav-item.active .nav-link {
    color: $gray-light;
    background-color: $white;
    border: 1px solid $nav-tabs-border-color;
    border-bottom-color: $white;
  }
}

.nav-stacked {
  > li {
    width: 100%;
  }
}

.nav-item.dropdown {
  .btn {
    font-size: $font-size-base;
  }

  .dropdown-item {
    font-family: 'Lato Light', sans-serif;
  }
}

.dropdown a {
  text-decoration: none;
}

.navmenu-default .nav > .active > a {
  color: $navmenu-default-link-active-color;
  background-color: $navmenu-default-link-active-bg;
}

/* Pagination */
.custom-pagination {
  display: inline-flex;

  .pagination-prev,
  .pagination-next,
  .pagination-first,
  .pagination-last,
  .pagination-page {
    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: $pagination-color;
      background-color: $pagination-bg;
      border: $pagination-border-width solid $pagination-border-color;

      &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        background-color: $pagination-hover-bg;
        border-color: $pagination-border-color;
        text-decoration: none;
      }
    }
  }

  .active {
    a {
      z-index: 3;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
      cursor: default;
    }
  }
}

/* Pre */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $pre-bg;
  border: 1px solid $pre-border-color;
  border-radius: 0;
}

/* Popover */
.popover {
  max-height: 50vh;
  overflow-y: auto;
  font-size: 1rem;
}

.tile + .popover {
  width: 100%;
}

.popover-content {
  padding: 9px 14px;
}

/* Table */
.table-col-12 {
  width: 100%;
}

.table-col-11 {
  width: 91.66666667%;
}

.table-col-10 {
  width: 83.33333333%;
}

.table-col-9 {
  width: 75%;
}

.table-col-8 {
  width: 66.66666667%;
}

.table-col-7 {
  width: 58.33333333%;
}

.table-col-6 {
  width: 50%;
}

.table-col-5 {
  width: 41.66666667%;
}

.table-col-4 {
  width: 33.33333333%;
}

.table-col-3 {
  width: 25%;
}

.table-col-2 {
  width: 16.66666667%;
}

.table-col-1 {
  width: 8.33333333%;
}

.table-hover tbody tr.table-row-no-hover:hover {
  background: none;
  color: inherit;
}

/* Text */
.font-italic {
  font-style: italic;
}

/* Toasts */
.toast-container {
  z-index: 8001;
}

/* Tooltip */
.tooltip {
  .tooltip-inner {
    font-size: 13px;
  }

  &.top .tooltip-arrow {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
  }

  &.left {
    padding: 0 5px;
    margin-left: -3px;

    .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: #000;
    }
  }
}

/* For Firefox... flex columns don't work nicely */
div.flex-column div.h-100 {
  min-height: 0;
}

.has-error {
  .form-control-feedback {
    z-index: 19;
  }

  .pull-right {
    margin-right: 17px;
  }
}

.container,
.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.form-switch {
  &-right {
    padding-left: 0;
    padding-right: 2.25rem;

    .form-check-input {
      float: right;
      margin-right: -2.25rem;
    }
  }
}

.input-group.input-group-sm > input[data-automation='smacs-text-input'].form-control {
  font-size: 1rem;
}
