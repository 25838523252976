$utilities: (
  'border-radius': (
    property: border-radius,
    class: border-rad-all,
    values: (
      2: 2px,
    ),
  ),
  'border-top-left-radius': (
    property: border-top-left-radius,
    class: border-rad-tl,
    values: (
      2: 2px,
    ),
  ),
  'border-top-right-radius': (
    property: border-top-right-radius,
    class: border-rad-tr,
    values: (
      2: 2px,
    ),
  ),
  'border-bottom-right-radius': (
    property: border-bottom-right-radius,
    class: border-rad-br,
    values: (
      2: 2px,
    ),
  ),
  'border-bottom-left-radius': (
    property: border-bottom-left-radius,
    class: border-rad-bl,
    values: (
      2: 2px,
    ),
  ),
);

@import 'smacs-variables';

$font-size-base: 1rem;
@import 'bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-toastr/toastr';
@import 'toast/toast-ngx';
@import 'smacs';
@import 'dragula';
@import 'forms';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/nova/theme.css';
@import '~primeng/resources/primeng.min.css';
@import 'smacs-primeng-overwrite';
@import 'ng-autocomplete-overwrite';
@import 'modals';
@import 'smacs-bootstrap-variables-overwrite';

// Contains bootstrap overwrites which are specific to Bootstrap 5 - not applicable to legacy!
a:not(.btn),
.btn-link {
  text-decoration: unset;

  &:hover {
    text-decoration: underline;
  }
}

.tooltip {
  &--white {
    .tooltip-inner {
      background-color: #fff;
      color: #0f1115;
    }

    .tooltip-arrow:before {
      border-top-color: #fff;
    }
  }
}

legend {
  float: unset;
}

.form-check-input {
  margin-top: 0.3em;
}

.form-check {
  [type='checkbox'] {
    border-radius: $border-radius;
  }
}

.form-switch {
  [type='checkbox'] {
    border-radius: 2em;
  }
}

.form-check {
  &.switch-lg .form-check-input {
    font-size: 1.5em;
  }
}

.nav-link {
  &.active {
    isolation: isolate;
  }
}

.input-group-text {
  font-size: 0.9rem;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

app-footer {
  .navbar-text a {
    color: #2dccd3;

    &:hover,
    &:focus {
      color: #24a3a9;
    }
  }
}

smacs-pagination {
  .btn-default:disabled,
  .btn-default.disabled,
  .btn-default:hover {
    border-color: #ccc !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 0;
}
