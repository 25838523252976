@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
@import 'ziro-static/fonts/smacs-icons/fontello';
@import 'ziro-static/fonts/material-design-icons/material-design-icons';
@import './smacs-variables';

// SERVICE ICONS

// Deskphone
.icon-search-deskphone:before {
  @extend .smacs-icons;
  content: '\e817';
}

// Extension Mobility
.icon-extension-mobility:before {
  @extend .smacs-icons;
  content: '\e818';
}

// CIPC
.icon-search-cipc:before {
  @extend .smacs-icons;
  vertical-align: middle;
  content: '\e80e';
}

// IM Softphone
.icon-search-softphone:before {
  content: '\e813';
  vertical-align: middle;
  @extend .smacs-icons;
}

// iPhone
.icon-search-iphone:before {
  content: '\e816';
  margin-top: -5px;
  vertical-align: middle;
  @extend .smacs-icons;
}

// Tablet
.icon-tablet:before {
  @extend .fa-solid;
  @extend .fa-tablet-screen-button;
}

// Android
.icon-search-android:before {
  @extend .smacs-icons;
  content: '\e80c';
}

// IM&P
.icon-search-imp:before {
  @extend .fa-solid;
  @extend .fa-comments;
}

// Extension Mobility
.icon-search-extension-mobility:before {
  @extend .fa-solid;
  @extend .fa-earth-americas;
}

// forms
.icon-forms:before {
  @extend .fa-brands;
  @extend .fa-wpforms;
}

// Voicemail
.icon-search-voicemail:before {
  @extend .fa-solid;
  @extend .fa-envelope;
}

// Ziro Provisioning Icon
.icon-environment:before {
  @extend .fa-solid;
  @extend .fa-server;
  vertical-align: -0.05em;
}

// Other types of phones (ex. CTI Ports)
.icon-search-phone-other:before {
  @extend .fa-solid;
  @extend .fa-square-phone;
}

// Single Number Reach
.icon-search-snr:before {
  @extend .smacs-icons;
  vertical-align: middle;
  content: '\e812';
}

// Primary Extension
.icon-search-primary-extension:before {
  content: 'contact_phone';
  @extend .material-icons;
}

// Microsoft Security Groups
.icon-security-groups:before {
  @extend .smacs-icons;
  content: '\e819';
}

// Azure
.icon-azure:before {
  @extend .smacs-icons;
  content: '\e81a';
}

// Extension
.icon-extension:before {
  @extend .fa-solid;
  @extend .fa-hashtag;
}

// Agent Details
.icon-search-agent:before {
  content: 'headset_mic';
  @extend .material-icons;
}

// OTHER ICONS

// Cluster
.icon-cluster:before {
  @extend .fa-solid;
  @extend .fa-cubes;
}

// Site
.icon-site:before {
  content: '\f3c5';
  @extend .fa;
}

// Table
.icon-table:before {
  @extend .fa-solid;
  @extend .fa-table;
}

// Adding a user
.icon-add-user:before {
  @extend .fa-solid;
  @extend .fa-user-plus;
}

// Delete
.icon-delete:before {
  @extend .fa-solid;
  @extend .fa-trash-can;
}

// Delete Outline
.icon-delete-outline:before {
  @extend .fa-regular;
  @extend .fa-trash-can;
}

// Home
.icon-home:before {
  @extend .fa-solid;
  @extend .fa-home;
}

// Preview
.icon-preview:before {
  content: 'find_in_page';
  @extend .material-icons;
}

// Clear
.icon-clear:before {
  @extend .fa-solid;
  @extend .fa-eraser;
}

// Download
.icon-download:before {
  @extend .fa-solid;
  @extend .fa-cloud-arrow-down;
}

// Export
.icon-export:before {
  @extend .fa-solid;
  @extend .fa-cloud-arrow-down;
}

// Reassign
.icon-reassign:before {
  content: 'transfer_within_a_station';
  @extend .material-icons;
}

// Reassign
.icon-testing:before {
  content: 'drag_handle';
  @extend .material-icons;
}

// Refresh
.icon-refresh:before {
  @extend .fa-solid;
  @extend .fa-arrows-rotate;
}

// Certificate
.icon-certificate:before {
  @extend .fa-solid;
  @extend .fa-certificate;
}

// Config
.icon-config:before {
  @extend .fa-solid;
  @extend .fa-gear;
}

// Automation
.icon-automation:before {
  @extend .fa-solid;
  @extend .fa-gears;
}

// LDAP
.icon-ldap:before {
  @extend .fa-solid;
  @extend .fa-users;
}

// File
.icon-file:before {
  @extend .fa-solid;
  @extend .fa-file;
}

// Edit
.icon-edit:before {
  @extend .fa-solid;
  @extend .fa-pencil;
}

// Copy
.icon-copy:before {
  @extend .fa-regular;
  @extend .fa-copy;
}

// Add
.icon-add:before {
  @extend .fa-solid;
  @extend .fa-circle-plus;
}

// Chevron Up
.icon-up:before {
  @extend .fa-solid;
  @extend .fa-chevron-up;
}

// Chevron Down
.icon-down:before {
  @extend .fa-solid;
  @extend .fa-chevron-down;
}

// Chevron Right
.icon-next:before {
  @extend .fa-solid;
  @extend .fa-chevron-right;
}

// Chevron Left
.icon-previous:before {
  @extend .fa-solid;
  @extend .fa-chevron-left;
}

// Inactive
.icon-inactive:before {
  @extend .fa-solid;
  @extend .fa-bell-slash;
}

// Warning
.icon-warning-triangle:before {
  @extend .fa-solid;
  @extend .fa-triangle-exclamation;
}

// Info
.icon-info-circle:before {
  @extend .fa-solid;
  @extend .fa-circle-info;
}

// Tooltip
.icon-tooltip:before {
  @extend .fa-solid;
  @extend .fa-circle-info;
}

// Ok
.icon-ok:before {
  @extend .fa-solid;
  @extend .fa-check;
}

// Lock
.icon-lock:before {
  @extend .fa-solid;
  @extend .fa-lock;
}

// Remove
.icon-remove:before {
  @extend .fa-solid;
  @extend .fa-xmark;
}

// Search
.icon-search:before {
  @extend .fa-solid;
  @extend .fa-magnifying-glass;
}

// Saving
.icon-saving:before {
  @extend .fa-solid;
  @extend .fa-spinner;
  @extend .fa-pulse;
}

// Loading
.icon-loading:before {
  @extend .fa-solid;
  @extend .fa-circle-notch;
  @extend .fa-spin;
}

// Calendar
.icon-calendar:before {
  @extend .fa-solid;
  @extend .fa-calendar-days;
}

// Archive
.icon-archive:before {
  @extend .fa-solid;
  @extend .fa-box-archive;
}

// Circle
.icon-circle:before {
  @extend .fa-solid;
  @extend .fa-circle;
}

// Sort
.icon-sort:before {
  @extend .fa-solid;
  @extend .fa-sort;
}

// 1-Click
.icon-one-click:before {
  @extend .smacs-icons;
  @extend .icon-cursor-click;
}

// Automation (top-nav)
.icon-automation-alt:before {
  @extend .smacs-icons;
  content: '\e805';
}

// Microsoft 365
.icon-search-microsoft-365:before {
  @extend .smacs-icons;
  content: '\e800';
  margin-left: -1px; // To adjust the whitespace that the icon has.
}

// CDR & MS Resource Account
.icon-phone-with-list:before {
  @extend .smacs-icons;
  content: '\e809';
}

// TEAMS CALLING
.icon-search-teams-calling:before {
  @extend .smacs-icons;
  content: '\e80a';
}

// Reports
.icon-reports:before {
  @extend .fa-solid;
  @extend .fa-chart-line;
}

// Undo
.icon-undo:before {
  @extend .fa-solid;
  @extend .fa-rotate-left;
}

// Webex-Calling
.icon-webex-calling:before {
  content: '\e811';
  @extend .smacs-icons;
}

// User
.icon-user:before {
  content: '\e814';
  @extend .smacs-icons;
}

// Dubber
.icon-dubber:before {
  content: '\e81b';
  @extend .smacs-icons;
}

// User Circle
.icon-user-circle:before {
  @extend .fa-solid;
  @extend .fa-circle-user;
}

// Delegate
.icon-delegate:before {
  @extend .fa-solid;
  @extend .fa-users;
}

// Wrench
.icon-wrench:before {
  @extend .fa-solid;
  @extend .fa-wrench;
}

.icon-star:before {
  @extend .fa-solid;
  @extend .fa-star;
}

// Import
.icon-import:before {
  @extend .fa-solid;
  @extend .fa-cloud-arrow-up;
}

// Exclamation
.icon-exclamation:before {
  @extend .fa-solid;
  @extend .fa-exclamation;
}

// Run
.icon-run:before {
  @extend .fa-solid;
  @extend .fa-circle-play;
}

// Sign In
.icon-sign-in:before {
  @extend .fa-solid;
  @extend .fa-right-to-bracket;
}

// Sign Out
.icon-sign-out:before {
  @extend .fa-solid;
  @extend .fa-right-from-bracket;
}

// Language
.icon-language:before {
  @extend .fa-solid;
  @extend .fa-language;
}

// Plus
.icon-plus:before {
  @extend .fa-solid;
  @extend .fa-plus;
  vertical-align: -0.05em;
}

// Minus
.icon-minus:before {
  @extend .fa-solid;
  @extend .fa-minus;
}

// History
.icon-history:before {
  @extend .fa-solid;
  @extend .fa-clock-rotate-left;
}

// Unavailable
.icon-unavailable:before {
  @extend .fa-solid;
  @extend .fa-ban;
}

// Filter
.icon-filter:before {
  @extend .fa-solid;
  @extend .fa-filter;
}

// Exclamation Circle
.icon-exclamation-circle:before {
  @extend .fa-solid;
  @extend .fa-circle-exclamation;
}

// External Link
.icon-external-link:before {
  @extend .fa-solid;
  @extend .fa-arrow-up-right-from-square;
}

// Fix It
.icon-fix-it:before {
  @extend .fa-solid;
  @extend .fa-wand-magic-sparkles;
}

// Exchange Icon
.icon-exchange:before {
  @extend .fa-solid;
  @extend .fa-arrow-right-arrow-left;
}

// Checked Square
.icon-checked-square:before {
  @extend .fa-solid;
  @extend .fa-square-check;
}

// Indeterminate Square
.icon-indeterminate-square:before {
  @extend .fa-solid;
  @extend .fa-square-minus;
}

// Unchecked Square
.icon-unchecked-square:before {
  @extend .fa-regular;
  @extend .fa-square;
}

// + Square
.icon-plus-square:before {
  @extend .fa-solid;
  @extend .fa-square-plus;
}

// Plus Circle
.icon-plus-circle:before {
  @extend .fa-solid;
  @extend .fa-circle-plus;
}

// Inbox
.icon-inbox:before {
  @extend .fa-solid;
  @extend .fa-inbox;
}

// Sorting Up
.icon-sort-up:before {
  @extend .fa-solid;
  @extend .fa-sort-up;
}

// Sorting Down
.icon-sort-down:before {
  @extend .fa-solid;
  @extend .fa-sort-down;
}

// About
.icon-about:before {
  @extend .fa-solid;
  @extend .fa-book;
}

// PowerShell
.icon-powershell:before {
  @extend .smacs-icons;
  content: '\e807';
}

// Clock
.icon-clock:before {
  @extend .fa-regular;
  @extend .fa-clock;
}

// Bell
.icon-bell:before {
  @extend .fa-solid;
  @extend .fa-bell;
}

// Back Button
.icon-back:before {
  @extend .fa-solid;
  @extend .fa-circle-arrow-left;
}

// Billing
.icon-billing:before {
  @extend .fa-solid;
  @extend .fa-dollar-sign;
}

// Audits
.icon-audits:before {
  @extend .smacs-icons;
  content: '\e80b';
  margin-left: 0; // To adjust the whitespace that the icon has
}

// Health
.icon-health:before {
  @extend .fa-solid;
  @extend .fa-suitcase-medical;
}

// Sliders
.icon-sliders:before {
  @extend .fa-solid;
  @extend .fa-sliders;
}

// Hash
.icon-search-hash:before {
  content: '\e810';
  @extend .smacs-icons;
}

// Distribution List
.icon-distribution-list:before {
  @extend .fa-solid;
  @extend .fa-rectangle-list;
}

// Check circle
.icon-check-circle:before {
  @extend .fa-regular;
  @extend .fa-circle-check;
}

// Check circle alt
.icon-check-circle-alt:before {
  @extend .fa-solid;
  @extend .fa-circle-check;
}

// Window close
.icon-window-close:before {
  @extend .fa-solid;
  @extend .fa-rectangle-xmark;
}

// Bullhorn
.icon-bullhorn:before {
  @extend .fa-solid;
  @extend .fa-bullhorn;
}

// Forbidden
.icon-forbidden:before {
  @extend .fa-solid;
  @extend .fa-circle-minus;
}

// Disaster Recovery
.icon-disaster-recovery:before {
  @extend .smacs-icons;
  content: '\e80d';
}

// Phone buttons
.icon-phone-buttons:before {
  @extend .fa-solid;
  @extend .fa-table-cells;
}

.icon-bandwidth:before {
  @extend .smacs-icons;
  content: '\E80F';
}

// Eye
.icon-eye:before {
  @extend .fa-solid;
  @extend .fa-eye;
}

// Light Bulb
.icon-light-bulb:before {
  @extend .fa-regular;
  @extend .fa-lightbulb;
}

// Send Email
.icon-send-email:before {
  @extend .fa-solid;
  @extend .fa-paper-plane;
}

// Server
.icon-server:before {
  @extend .fa-solid;
  @extend .fa-server;
}

// Key
.icon-key:before {
  @extend .fa-solid;
  @extend .fa-key;
}

// Calendar check outline
.icon-calendar-check-outline:before {
  @extend .fa-regular;
  @extend .fa-calendar-check;
}

.icon-circle ~ .icon-send-email:before {
  // the icon has a weird shape, so we offset it to make it appear more centred.
  margin-right: 5px;
}

// Shield
.icon-shield:before {
  @extend .fa-solid;
  @extend .fa-shield-halved;
}

// Group
.icon-group:before {
  content: '\e81c';
  @extend .smacs-icons;
}

// Wifi
.icon-wifi:before {
  @extend .fa-solid;
  @extend .fa-wifi;
}

// Hand
.icon-hand:before {
  @extend .fa-regular;
  @extend .fa-hand-pointer;
}

// PDF
.icon-pdf:before {
  @extend .fa-regular;
  @extend .fa-file-pdf;
}

// Address Card
.icon-address-card:before {
  @extend .fa-solid;
  @extend .fa-address-card;
}

// Building
.icon-building:before {
  @extend .fa-solid;
  @extend .fa-building;
}

// Code Branch
.icon-code-branch:before {
  @extend .fa-solid;
  @extend .fa-code-branch;
}

// Add Document
.icon-add-document:before {
  @extend .smacs-icons;
  content: '\e81d';
}

// Draft
.icon-draft:before {
  @extend .smacs-icons;
  content: '\e81e';
}

// Documents
.icon-documents:before {
  @extend .smacs-icons;
  content: '\e81f';
}

// Phone Alt
.icon-phone-alt:before {
  @extend .smacs-icons;
  content: '\e820';
}

// Card Add
.icon-card-add:before {
  @extend .smacs-icons;
  content: '\e821';
}

// ZIRO Icon
.icon-ziro-sm:before {
  @extend .smacs-icons;
  content: '\e822';
}

// ZIRO Icon
.icon-notes:before {
  @extend .smacs-icons;
  content: '\e823';
}

// Monitor
.icon-monitor:before {
  @extend .smacs-icons;
  content: '\e822';
}

// Phone Dial
.icon-phone-dial:before {
  @extend .smacs-icons;
  content: '\e823';
}

// Patch so that material icons have same height as text / font-awesome icons
.material-icons {
  font-size: inherit;
}

// Sizing Options
.fa-4x,
.icon-4x {
  font-size: 4em;
}

.fa-3x,
.icon-3x {
  font-size: 3em;
}

.fa-2x,
.icon-2x {
  font-size: 2em;
}

.icon-success {
  color: $brand-success;
}

.icon-danger {
  color: $brand-danger;
}

.icon-info {
  color: $brand-info;
}

.icon-warning {
  color: $brand-warning;
}

.icon-default {
  color: $gray-light;
}
