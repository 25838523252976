//@import 'src/scss/smacs-bootstrap-variables-overwrite';
.smacs-forms-asterisk {
  margin-right: 0.25rem;
}

.smacs-forms-info {
  margin-left: 0.25rem;
}

.form-control:focus {
  box-shadow: none;
}

.smacs-forms-validation-message {
  @include font-size(80%);
}

.has-feedback {
  &.smacs-forms-info,
  .smacs-forms-info {
    color: $brand-info;

    .smacs-horizontal-form-validation-message {
      color: $brand-info;
    }

    .form-control,
    .ng-select-container,
    .ngx-codemirror-container {
      border-color: $brand-info !important;
    }
  }

  &.smacs-forms-success,
  .smacs-forms-success {
    color: $brand-success;

    .smacs-horizontal-form-validation-message {
      color: $brand-success;
    }

    .form-control,
    .ng-select-container,
    .ngx-codemirror-container {
      border-color: $brand-success !important;
    }
  }

  &.smacs-forms-warning,
  .smacs-forms-warning {
    color: $brand-warning;

    .smacs-horizontal-form-validation-message {
      color: $brand-warning;
    }

    .form-control,
    .ng-select-container,
    .ngx-codemirror-container {
      border-color: $brand-warning !important;
    }

    ng-dropdown-panel {
      border-color: $brand-warning;
    }

    .ng-select-container .ng-spinner-loader {
      border-top-color: $brand-warning;
      border-right-color: $brand-warning;
      border-bottom-color: $brand-warning;
      border-left-color: transparent;
    }

    .form-control-feedback.icon-warning-triangle {
      top: 1px;
    }
  }

  &.smacs-forms-error,
  .smacs-forms-error {
    color: $brand-danger;

    .smacs-horizontal-form-validation-message {
      color: $brand-danger;
    }

    .form-control,
    .ng-select-container,
    .ngx-codemirror-container {
      border-color: $brand-danger !important;
    }
  }

  .ng-multi-select-async {
    .ng-arrow-wrapper {
      display: none;
    }
    .ng-clear-wrapper,
    .ng-spinner-loader {
      right: 25px;
      z-index: 6;
    }
  }
}

smacs-select {
  .smacs-select {
    &--hidden-arrow {
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  .ng-select-async:not(.ng-select-filtered) .ng-dropdown-panel {
    opacity: 0 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    min-height: 37px;
  }
  .ng-option-label {
    min-width: 1px;
    min-height: 1px;
    display: inline-block;
  }
  .smacs-forms-warning {
    .ng-arrow-wrapper {
      margin-right: 23px;
    }
  }

  .ng-select-container {
    box-shadow: none !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled:hover {
    background-color: #f5faff;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:not(:hover) {
    background-color: #fff;
  }

  .form-group:not(.smacs-forms-error):not(.smacs-forms-warning):not(.smacs-forms-success) .ng-select.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: #a5d5e4;
    }
    > .ng-select-container {
      border-color: #a5d5e4;
    }
  }
}

smacs-radio {
  .col-9 {
    padding-top: $input-padding-y;
  }
}

.disabled + .CodeMirror {
  background: #e9ecef;
}

.custom-control.custom-control--right {
  padding-right: 24px;
  padding-left: 0;
  margin-left: 16px;
  margin-right: 0;
  .custom-control-label:before,
  .custom-control-label:after {
    right: -1.5rem;
    left: initial;
  }
}

smacs-select-config {
  smacs-text {
    .form-group {
      margin-bottom: 10px;
    }
  }
  smacs-button button.match-input-lg {
    min-height: 0 !important;
  }
}

smacs-text-config {
  smacs-text {
    .btn-expression-editor {
      z-index: 2;
    }
  }
}

.input-group-sm .form-control-feedback {
  height: 31px;
  line-height: 31px;
  width: 31px;
}

.input-group-lg .form-control-feedback {
  font-size: 20px;
  height: 48px;
  width: 48px;
  line-height: 48px;
}

html {
  .gu-mirror {
    z-index: 7779 !important;
    .btn:not(:disabled):not(.disabled) {
      cursor: grabbing;
    }
    &.no-drop {
      .btn:not(:disabled):not(.disabled) {
        cursor: no-drop;
      }
    }
  }
  .modal-backdrop {
    z-index: 7777 !important;
  }
  .modal {
    z-index: 7778 !important;
  }
}
