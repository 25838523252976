form {
  .is-invalid {
    .control-label,
    .col-form-label,
    .validation-message {
      color: $brand-danger;
    }

    .form-control {
      border: 1px solid;
      border-color: $brand-danger !important;

      &:focus {
        box-shadow: 0 0 0.2rem 1px $brand-danger !important;
      }
    }
  }

  .validation-message {
    margin-top: 5px;
  }
}

.ng-select.ng-select-async .ng-select-container .ng-value-container {
  padding-left: 30px;

  .ng-input > input {
    padding-left: 20px;
  }
}

.ng-select-multiple.ng-select-async .ng-select-container .ng-value-container .ng-input > input {
  padding-left: 0;
}

.smacs-filter-select {
  &.ng-select .ng-select-container {
    .ng-value-container {
      padding-top: 2px;
      padding-left: 4px;

      .ng-value {
        margin: 0 4px 2px 4px;
      }
    }
  }

  &.ng-select-single .ng-select-container {
    height: 1rem;
  }

  .ng-dropdown-panel {
    width: auto;
    min-width: 100%;
  }
}

smacs-filter-input input.form-control,
smacs-filter-input .input-group > .form-control:focus {
  z-index: 3;
}

.form-check-input[type='checkbox'] {
  border-radius: 2px;
}
