@import 'smacs-bootstrap-variables-overwrite';

/**
 * This file contains variables that no longer exist in bootstrap 4 but our app cannot build without
 */

////== Scaffolding

$font-size-base: 15px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//== Components

$padding-base-vertical: 6px;

$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;
$btn-default-color-hover: darken($btn-default-color, 5%);
$btn-default-bg-hover: darken($btn-default-bg, 5%);
$btn-default-border-hover: darken($btn-default-border, 5%);

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);
$btn-primary-bg-hover: #26aeb4;
$btn-primary-border-hover: #24a4a9;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);
$btn-success-bg-hover: #2cc72a;
$btn-success-border-hover: #29bd27;

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);
$btn-warning-bg-hover: #fd6017;
$btn-warning-border-hover: #fc570b;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);
$btn-danger-bg-hover: #fa2134;
$btn-danger-border-hover: #fa1428;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);

$legend-border-color: #e5e5e5;

////== Grid system
$grid-gutter-width: 30px;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($brand-info, 60%);
$navbar-inverse-bg: $brand-info;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: lighten($brand-info, 60%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;

//== Tables
$table-border-color: #dee2e6;
$table-bg: transparent;
