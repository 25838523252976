@import './smacs-bootstrap-variables-overwrite.scss';

p-calendar {
  .smacs-primeng-datepicker {
    width: 100%;
    .smacs-primeng-datepicker-input {
      background: none;
      font-size: 1rem;
      font-family: 'Lato Bold', sans-serif;
      width: calc(100% - 35px);
      height: calc(1.42857143em + 0.75rem + 2px);
      border: 1px solid $btn-default-border;
      border-radius: $border-radius;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover,
      &:focus {
        border: 1px solid $btn-default-border-hover !important;
        border-right: 0 !important;
      }

      ~ .p-button-icon-only {
        padding: 0;
      }
    }
    .p-button {
      background-color: $btn-primary-bg;
      border: $btn-primary-border;
    }
    .p-datepicker {
      min-width: unset;
    }
    .ui-button-icon-only {
      height: 32px;
      color: $btn-default-color;
      background-color: $btn-default-bg;
      border: 1px solid $btn-default-border;
      border-radius: 0;
      top: 1px;
      &:hover,
      &:focus {
        color: $btn-default-color-hover;
        background-color: $btn-default-bg-hover;
        border-color: $btn-default-border-hover;
        border-left: 1px solid !important;
        border-left-color: $btn-default-border-hover !important;
      }
      .ui-button-icon-left {
        top: 11px;
      }
    }
  }
}

p-dropdown {
  .ui-dropdown {
    width: 100%;
    border: 1px solid $gray-400;
    border-radius: 0;
    &:not(.ui-state-disabled):hover,
    &:hover {
      border: 1px solid $gray-400;
    }
    .ui-dropdown-label {
      padding-top: 5px;
      padding-bottom: 4px;
    }
  }
}

.is-invalid {
  .ui-dropdown {
    border: 1px solid $brand-danger;
  }
}

ziro-call-logs-query-editor-keyword {
  .p-calendar .p-inputtext.keyword-datepicker-input {
    border: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    width: 0;
  }
}

smacs-datatable-date-filter {
  position: relative;
  .dropdown-menu,
  .dropdown-item {
    padding: 0;
  }
  .dropdown-menu {
    max-height: 100vh;
  }
  .p-datepicker {
    border: 0;
    border-radius: 0;
    padding: 0;
    button,
    table {
      font-size: 15px;
    }
  }
}
