@import 'smacs-variables';

.smacs-left-nav {
  position: fixed;
  margin-top: -20px;
  background: $left-nav-bg;
  padding: 10px 0 20px 0;
  color: $left-nav-color;
  z-index: 1040;
  overflow-y: auto;
  height: 100%;
}

.smacs-left-nav .list-group {
  padding: 0;
  margin: 0;
}

.smacs-left-nav a.list-group-item {
  color: inherit;
  background: inherit;
  border: none;
  font-size: 15px;
  font-family: 'Lato Light', sans-serif;
  text-align: left;
  padding: 8px 10px;
  margin: 0;
  border-bottom: thin solid darken($left-nav-bg, 5%);
  .svg-icon-class {
    fill: $left-nav-color;
  }
}

.smacs-left-nav a.list-group-item:hover,
.smacs-left-nav a.list-group-item:focus {
  background: darken($left-nav-bg, 5%);
  color: $brand-primary;
  .svg-icon-class {
    fill: $brand-primary;
  }
}

.smacs-left-nav hr {
  border-top: 1px solid lighten($left-nav-bg, 10%);
  margin-top: 10px;
  margin-bottom: 5px;
}

.smacs-left-nav legend {
  padding: 4px 10px;
  font-family: 'Lato Light';
  font-size: 16px;
  text-transform: uppercase;
  color: $left-nav-color;
  border-top: 1px solid lighten($left-nav-bg, 10%);
  border-bottom: none;
  margin-bottom: 0;
  background-color: darken($left-nav-bg, 5%);
}

.smacs-left-nav .text-success {
  color: $brand-success;
}

.smacs-left-nav .muted {
  color: lighten($left-nav-bg, 30%) !important;
}

// When scroll-spy follows the scroll in site.component,
// the nav items go in focus and the last visited nav item remains in focus
.nav > li > a:focus {
  background: none;
}

// We need to offset the anchor we are scroll spying
// (du-scroll offset did not work well so we resolved to using css)
.small-scroll-spy-spacer {
  margin-top: -70px;
  height: 70px;
}

.site-left-nav {
  position: fixed;
  z-index: 0;
  width: 300px;
  max-height: 100%;
  top: 70px;
  bottom: 50px;
  overflow-y: scroll;
}

.avatar-pic {
  height: 72px;
  width: 72px;
  border-radius: 100px;
  border: 2px solid lighten($left-nav-bg, 20%);
  margin-bottom: -40px;
  object-fit: cover;
}

.contact-card {
  margin: 10px;
  padding: 15px;
  font-size: 13px;
  background: darken($left-nav-bg, 20%);
  text-align: center;
  border: thin solid lighten($left-nav-bg, 10%);
  overflow-wrap: break-word;
}
