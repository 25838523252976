@import '../smacs-variables';

.smacs-admin-menu {
  border-radius: 0;
  border-right: 1px solid $nav-tabs-border-color;
  min-width: 250px;
  overflow-y: auto;
  z-index: 2;

  .nav-link {
    color: $navmenu-default-color;
    padding: 10px 15px;

    &:hover {
      color: $navmenu-default-brand-hover-color;
    }
  }
}
