// This file should contain custom labels that can be reused across the app

.badge-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .badge {
    margin-right: 3px;
    font-size: 12px;
    line-height: 0.8;
  }
}
