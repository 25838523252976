@import '../smacs-variables';
@import 'smacs-toast';

body {
  overflow-x: hidden;
  app-root {
    overflow-x: auto;
  }
}

.toast-container {
  > .toast-success {
    background-color: rgba($brand-success, 0.8);
  }

  > .toast-success .toast-icon {
    color: $brand-success;
  }

  > .toast-error {
    background-color: rgba($brand-danger, 0.8);
  }

  > .toast-error .toast-icon {
    color: $brand-danger;
  }

  > .toast-info {
    background-color: rgba($brand-info, 0.8);
  }

  > .toast-info .toast-icon {
    color: $brand-info;
  }

  > .toast-warning {
    background-color: rgba($brand-warning, 0.8);
  }

  > .toast-warning .toast-icon {
    color: $brand-warning;
  }
}
