@import 'smacs-bootstrap-variables';

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;
$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;
$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;
$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;
$line-height-large: 1.33;
$line-height-small: 1.5;
$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 3px;
$table-bg-hover: #f5f5f5;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$grid-gutter-width: 30px;
$grid-float-breakpoint: 768px;
$container-smooth: $container-lg;
$navbar-height: 50px;
$navbar-padding-horizontal: floor($grid-gutter-width * 0.5);
$navbar-padding-vertical: ($navbar-height - $line-height-computed) * 0.5;
$navmenu-width: 250px;
$navmenu-margin-vertical: (0.5 * $line-height-computed);
$navmenu-default-color: #777;
$navmenu-default-bg: #f8f8f8;
$navmenu-default-border: darken($navmenu-default-bg, 6.5%);
$navmenu-default-link-color: #777;
$navmenu-default-link-hover-color: #333;
$navmenu-default-link-hover-bg: transparent;
$navmenu-default-link-active-color: #555;
$navmenu-default-link-active-bg: darken($navmenu-default-bg, 6.5%);
$navmenu-default-link-disabled-color: #ccc;
$navmenu-default-link-disabled-bg: transparent;
$navmenu-default-brand-color: $navmenu-default-link-color;
$navmenu-default-brand-hover-color: darken($navmenu-default-link-color, 10%);
$navmenu-default-brand-hover-bg: transparent;
$navmenu-inverse-color: $gray-light;
$navmenu-inverse-bg: #222;
$navmenu-inverse-border: darken($navmenu-inverse-bg, 10%);
$navmenu-inverse-link-color: $gray-light;
$navmenu-inverse-link-hover-color: #fff;
$navmenu-inverse-link-hover-bg: transparent;
$navmenu-inverse-link-active-color: $navmenu-inverse-link-hover-color;
$navmenu-inverse-link-active-bg: darken($navmenu-inverse-bg, 10%);
$navmenu-inverse-link-disabled-color: #444;
$navmenu-inverse-link-disabled-bg: transparent;
$navmenu-inverse-brand-color: $navmenu-inverse-link-color;
$navmenu-inverse-brand-hover-color: #fff;
$navmenu-inverse-brand-hover-bg: transparent;
$navmenu-inverse-search-bg: lighten($navmenu-inverse-bg, 25%);
$navmenu-inverse-search-bg-focus: #fff;
$navmenu-inverse-search-border: $navmenu-inverse-bg;
$navmenu-inverse-search-placeholder-color: #ccc;
$nav-link-padding: 10px 15px;
$nav-tabs-active-link-hover-border-color: #ddd;
$nav-tabs-border-color: #ddd;
$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);
$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);
$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);
$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);
$alert-border-radius: $border-radius-base;
$alert-fixed-width: $screen-md;
