@import '../smacs-bootstrap-variables';
.modal-body--dynamic {
  &__scrollable {
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-body--full-height {
  height: 75vh;
}

.modal-content {
  .modal-header--bordered {
    border-bottom: 1px solid $legend-border-color;
  }

  .modal-footer--bordered {
    border-top: 1px solid $legend-border-color;
  }
}
