@import 'smacs-bootstrap-variables-overwrite';

$spinner-default-height: 10rem;
$spinner-default-width: 20rem;

.loading-spinner {
  position: relative;
  height: $spinner-default-height;
  width: $spinner-default-width;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ziro_rotate {
  animation: rotating 1s linear infinite;
  transform-origin: 50% 50%;
  width: 85px;
  height: 85px;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
