@import '../smacs-variables';

.smacs-top-nav {
  height: 65px;

  > .navbar-collapse {
    height: 100%;
  }

  &.bg-info {
    border-bottom: 1px solid $navbar-inverse-border;
  }

  .navbar-brand {
    img,
    svg {
      height: 1.9rem;
    }
  }

  .navbar-form {
    min-width: 330px;
    top: 7px;
    position: relative;
    input[type='text'] {
      background-clip: unset;
    }
  }

  &.bg-info {
    .navbar-nav {
      > li {
        .ziro-prov-link {
          color: #fff;
          font-size: 0.8rem;
          padding: 0.375rem 0.75rem;
        }
      }
      > li,
      .smacs-top-nav-edit-dropdown {
        > a,
        > button {
          color: $navbar-inverse-link-color;
          box-shadow: none;
          font-size: 15px;
          &:hover {
            color: $navbar-inverse-link-hover-color;
          }
        }
        .smacs-top-nav-cluster {
          color: $white;
        }
        .dropdown-toggle::after {
          vertical-align: 0.12em;
        }
        > i {
        }
        border: none;
      }
      .ziro-prov-container {
        padding: 0.3rem 0 0.3rem 0.3rem;

        &--dropdown-menu {
          > a {
            &:hover {
              background-color: #f8f9fa;
            }
          }
        }
        > a {
          &:hover {
            background-color: unset;
          }
          > i {
            margin-right: 2px;
          }
        }
      }
    }
  }

  &:not(.bg-info) {
    .navbar-nav {
      > li {
        > a:not(.disabled).ziro-prov-link {
          &:hover {
            background-color: #26aeb4;
          }
        }
      }
      .ziro-prov-container {
        > a {
          color: #fff;
        }
      }
    }
  }

  .navbar-nav {
    align-items: center;
    gap: 25px;
    margin-right: -10px;

    > li:not(.environment) {
      height: 30px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > li {
      > a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > {
          .icon-reports {
            margin-top: 1px;
          }
        }

        > .icon-automation-alt {
          margin-left: -6px;
          margin-bottom: 2px;
        }

        > .icon-config {
          margin-top: 2px;
        }

        > .icon-user-circle {
          margin-top: 1px;
        }
      }

      > a,
      > button {
        cursor: pointer;
        color: $brand-info;
        border: none;
        padding: 0;
      }

      > button {
        display: inline-block;
      }
      .smacs-top-nav-cluster {
        color: $white;
      }
      .smacs-top-nav-logout {
        color: $brand-info;
      }
    }

    > li > a.default-cursor {
      cursor: default;
    }
  }

  .top-nav-bar-language-selector {
    cursor: pointer;

    .language-selection-menu {
      > button {
        font-size: 20px;
      }

      .dropdown-menu {
        .dropdown-item,
        .dropdown-item:hover {
          color: $brand-info;
        }

        .language-tab-selected {
          background-color: $brand-info;
        }

        .language-tab-selected,
        .language-tab-selected:hover,
        .language-tab-selected.active a:hover {
          color: $white;
        }
      }
    }

    > a,
    button > .fa {
      font-size: 20px;
    }
  }
}

.smacs-top-nav-navbar__icon {
  font-size: 25px;
}
