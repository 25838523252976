// <btn tooltip="hi" disabled> will NOT display a tooltip. In most cases, when we disable a button, we want to show
// a tooltip explaining why the button is disabled.
// The solution for this is to wrap the element, i.e. <span class="tooltip-wrapper"> <btn disabled> </span>
// The non-obvious consequences of this are that:
//
// 1) The tooltip-wrapper should be an inline block
// 2) "pointer-events: none;" and "cursor: not-allowed" are mutually exclusive within a button
.tooltip-wrapper {
  display: inline-block;
}
.tooltip-wrapper-disabled {
  cursor: not-allowed;
}

.tooltip-wrapper .btn[disabled] {
  pointer-events: none;
}

.system-status-tooltip {
  .tooltip-inner {
    max-width: 480px;
    white-space: pre-wrap;
  }
}
