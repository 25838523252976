/** START COPY PASTE FROM 'node_modules/material-design-icons/iconfont/material-icons.css'
 We need this to be able to override the font file paths instead of import the css file since we can't override the
 font-face when importing css into less apparently.
*/

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/** END COPY PASTE FROM 'node_modules/material-design-icons/iconfont/material-icons.css' */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('/static/fonts/material-design-icons/MaterialIcons-Regular.woff2') format('woff2'),
    url('/static/fonts/material-design-icons/MaterialIcons-Regular.woff') format('woff'),
    url('/static/fonts/material-design-icons/MaterialIcons-Regular.ttf') format('truetype');
}
