@import 'smacs-bootstrap-variables';
@import 'smacs-jasny-variables';

$white: #fff;

// default for background for login/ss page
$login-ss-bg-image: '';

$login-ss-bg-color: $brand-info;
$company-logo: '/static/img/stack8-logo.jpg';

// Call to action button vars
$btn-call-to-action-bg: $brand-warning;
$btn-call-to-action-color: #fff;

//left-nav-color:
$left-nav-color: #fff;
$left-nav-bg: $brand-info;

// Fonts
$default-smacs-font: 'Lato Light', sans-serif;
